define("shared/mixins/catalog-app", ["exports", "ui/utils/parse-version", "shared/utils/evaluate", "ui/utils/constants"], function (_exports, _parseVersion, _evaluate, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    previewTabDidChange: Ember.observer('previewTab', 'previewOpen', function () {
      var _this = this;

      var files = Ember.get(this, 'selectedTemplateModel.filesAsArray') || [];
      var previewTab = Ember.get(this, 'previewTab');
      var found = files.findBy('name', previewTab);

      if (!found) {
        return;
      }

      Ember.set(this, 'decoding', true);
      Ember.run.next(function () {
        if (!found.decoded) {
          Ember.setProperties(found, {
            body: atob(found.body),
            decoded: true
          });
        }

        Ember.setProperties(_this, {
          selectedFileContetnt: found.body,
          decoding: false
        });
      });
    }),
    templateChanged: Ember.observer('editable.selectedTemplateUrl', 'templateResource.defaultVersion', function () {
      var _this$editable$select = this.editable.selectedTemplateUrl,
          selectedTemplateUrl = _this$editable$select === void 0 ? '' : _this$editable$select;

      if (!Ember.isEmpty(selectedTemplateUrl)) {
        this.getTemplate.perform();
      }
    }),
    filenames: Ember.computed('selectedTemplateModel', 'selectedTemplateModel.filesAsArray.[]', function () {
      var files = Ember.get(this, 'selectedTemplateModel.filesAsArray').map(function (file) {
        return {
          label: file.name,
          value: file.name
        };
      });
      files.addObject({
        label: 'answers.yaml',
        value: 'answers'
      });
      return files.sortBy('label');
    }),
    sortedVersions: Ember.computed('versionsArray', 'templateResource.defaultVersion', function () {
      var out = Ember.get(this, 'versionsArray').sort(function (a, b) {
        if (a.sortVersion && b.sortVersion) {
          return (0, _parseVersion.compare)(a.sortVersion, b.sortVersion);
        } else {
          return (0, _parseVersion.compare)(a.version, b.version);
        }
      });
      var def = Ember.get(this, 'templateResource.defaultVersion');

      if (Ember.get(this, 'showDefaultVersionOption') && Ember.get(this, 'defaultUrl')) {
        out.unshift({
          version: Ember.get(this, 'intl').t('newCatalog.version.default', {
            version: def
          }),
          link: 'default'
        });
      }

      return out;
    }),
    defaultUrl: Ember.computed('templateResource.defaultVersion', 'versionLinks', function () {
      var defaultVersion = Ember.get(this, 'templateResource.defaultVersion');
      var versionLinks = Ember.get(this, 'versionLinks');

      if (defaultVersion && versionLinks && versionLinks[defaultVersion]) {
        return versionLinks[defaultVersion];
      }

      return null;
    }),
    answers: Ember.computed('selectedTemplateModel.allQuestions.@each.{variable,answer}', function () {
      var out = {};
      var allQuestions = Ember.get(this, 'selectedTemplateModel.allQuestions') || [];
      var filteredQuestions = allQuestions.filter(function (q) {
        return (0, _evaluate.evaluate)(q, allQuestions);
      });
      filteredQuestions.forEach(function (item) {
        out[item.variable] = (0, _evaluate.stringifyAnswer)(item.answer);
      });
      var customAnswers = Ember.get(this, 'selectedTemplateModel.customAnswers') || {};
      Object.keys(customAnswers).forEach(function (key) {
        out[key] = (0, _evaluate.stringifyAnswer)(customAnswers[key]);
      });
      return out;
    }),
    newExternalId: Ember.computed('selectedTemplateModel.id', function () {
      return "".concat(_constants.default.EXTERNAL_ID.KIND_CATALOG).concat(_constants.default.EXTERNAL_ID.KIND_SEPARATOR).concat(Ember.get(this, 'selectedTemplateModel.id'));
    }),
    updateReadme: function updateReadme() {
      var _this2 = this;

      var model = Ember.get(this, 'selectedTemplateModel');
      Ember.setProperties(this, {
        readmeContent: null,
        appReadmeContent: null,
        noAppReadme: false
      });

      if (model && model.hasLink('readme')) {
        model.followLink('readme').then(function (response) {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          Ember.set(_this2, 'readmeContent', response);
        });
      }

      if (model && model.hasLink('app-readme')) {
        Ember.set(this, 'noAppReadme', false);
        model.followLink('app-readme').then(function (response) {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          Ember.set(_this2, 'appReadmeContent', response);

          if (!response) {
            Ember.set(_this2, 'noAppReadme', true);
          }
        });
      } else {
        Ember.set(this, 'noAppReadme', true);
      }
    }
  });

  _exports.default = _default;
});