define("shared/mixins/node-driver", ["exports", "shared/mixins/new-or-edit", "shared/mixins/manage-labels", "ui/utils/add-view-action", "shared/utils/util", "shared/utils/parse-unit", "ui/utils/constants", "jquery"], function (_exports, _newOrEdit, _manageLabels, _addViewAction, _util, _parseUnit, _constants, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDisplayLocation = getDisplayLocation;
  _exports.getDisplaySize = getDisplaySize;
  _exports.registerDisplayLocation = registerDisplayLocation;
  _exports.registerDisplaySize = registerDisplaySize;
  _exports.default = _exports.DynamicDependentKeysProperty = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var DynamicDependentKeysProperty = function DynamicDependentKeysProperty(property) {
    _classCallCheck(this, DynamicDependentKeysProperty);

    var driver = property.driver,
        keyOrKeysToWatch = property.keyOrKeysToWatch,
        getDisplayProperty = property.getDisplayProperty;
    Ember.setProperties(this, {
      driver: driver,
      keyOrKeysToWatch: keyOrKeysToWatch
    });

    if (getDisplayProperty) {
      Ember.set(this, 'getDisplayProperty', getDisplayProperty);
    } else {
      if (keyOrKeysToWatch) {
        var keyToGet = keyOrKeysToWatch;
        Ember.set(this, 'getDisplayProperty', function () {
          return Ember.get(this, keyToGet);
        });
      }
    }
  }; // Map of location objects -> { driver, keyOrKeysToWatch, getLocation }
  // driver: driver name (e.g. digitial ocean, azure)
  // keyOrKeysToWatch: string | map of strings corresponding to key or keys on config storing the location
  // getLocation: if multiple location keys you may provide an override getting function to fetch and massage display location to your liking
  // Custom UIs should call registerDisplayLocation{new Location} to register new entries.


  _exports.DynamicDependentKeysProperty = DynamicDependentKeysProperty;
  var DISPLAY_LOCATIONS = [];
  var DISPLAY_SIZES = [];

  function _initBuiltInSizes() {
    var CONFIG_SIZE_KEYS = [{
      driver: 'aliyunecs',
      keyOrKeysToWatch: 'config.instanceType'
    }, {
      driver: 'amazonec2',
      keyOrKeysToWatch: 'config.instanceType'
    }, {
      driver: 'azure',
      keyOrKeysToWatch: 'config.size'
    }, {
      driver: 'digitalocean',
      keyOrKeysToWatch: 'config.size'
    }, {
      driver: 'exoscale',
      keyOrKeysToWatch: 'config.instanceProfile'
    }, {
      driver: 'linode',
      keyOrKeysToWatch: 'config.instanceType'
    }, {
      driver: 'packet',
      keyOrKeysToWatch: 'config.plan'
    }, {
      driver: 'rackspace',
      keyOrKeysToWatch: 'config.flavorId'
    }, {
      driver: 'vmwarevsphere',
      keyOrKeysToWatch: ['config.memorySize', 'config.cpuCount'],
      getDisplayProperty: function getDisplayProperty() {
        var size = (0, _parseUnit.formatSi)(Ember.get(this, 'config.memorySize') * 1048576, 1024, 'iB');
        return "".concat(size, ", ").concat(Ember.get(this, 'config.cpuCount'), " Core");
      }
    }];
    CONFIG_SIZE_KEYS.forEach(function (size) {
      registerDisplaySize(new DynamicDependentKeysProperty(size));
    });
  }

  function _initBuiltInLocations() {
    var CONFIG_LOCATION_KEYS = [{
      driver: 'aliyunecs',
      keyOrKeysToWatch: ['config.region', 'config.zone'],
      getDisplayProperty: function getDisplayProperty() {
        return "".concat(Ember.get(this, 'config.region')).concat(Ember.get(this, 'config.zone'));
      }
    }, {
      driver: 'amazonec2',
      keyOrKeysToWatch: ['config.region', 'config.zone'],
      getDisplayProperty: function getDisplayProperty() {
        return "".concat(Ember.get(this, 'config.region')).concat(Ember.get(this, 'config.zone'));
      }
    }, {
      driver: 'azure',
      keyOrKeysToWatch: 'config.location'
    }, {
      driver: 'digitalocean',
      keyOrKeysToWatch: 'config.region'
    }, {
      driver: 'exoscale',
      keyOrKeysToWatch: 'config.availabilityZone'
    }, {
      driver: 'linode',
      keyOrKeysToWatch: 'config.region'
    }, {
      driver: 'packet',
      keyOrKeysToWatch: 'config.facilityCode'
    }, {
      driver: 'rackspace',
      keyOrKeysToWatch: 'config.region'
    }, {
      driver: 'vmwarevsphere',
      keyOrKeysToWatch: null
    }];
    CONFIG_LOCATION_KEYS.forEach(function (location) {
      registerDisplayLocation(new DynamicDependentKeysProperty(location));
    });
  }

  _initBuiltInLocations();

  _initBuiltInSizes();

  function getDisplayLocation(driver) {
    return DISPLAY_LOCATIONS.findBy('driver', driver);
  }

  function getDisplaySize(driver) {
    return DISPLAY_SIZES.findBy('driver', driver);
  }

  function registerDisplayLocation(location) {
    DISPLAY_LOCATIONS.push(location);
  }

  function registerDisplaySize(size) {
    DISPLAY_SIZES.push(size);
  }

  var _default = Ember.Mixin.create(_newOrEdit.default, _manageLabels.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    driverName: null,
    errors: null,
    showEngineUrl: true,
    // On some drivers this isn't configurable
    model: null,
    labelResource: Ember.computed.alias('model'),
    actions: {
      errorHandler: function errorHandler(err) {
        var shouldClearPreviousErrors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var errors = this.errors;

        if (shouldClearPreviousErrors) {
          errors = Ember.set(this, 'errors', []);
        }

        if (errors) {
          if (Ember.isArray(err)) {
            errors.pushObjects(err);
          } else {
            errors.pushObject(err);
          }
        } else {
          errors = [err];
        }

        Ember.set(this, 'errors', errors);
      },
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential() {},
      addLabel: (0, _addViewAction.addAction)('addLabel', '.key'),
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        Ember.set(this, 'labelResource.labels', out);
      },
      cancel: function cancel() {
        if (this.close) {
          this.close();
        }
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'editing') && typeof Ember.get(this, 'bootstrap') === 'function') {
        this.initEngineUrl();
        this.bootstrap();
      }

      Ember.set(this, 'model.namespaceId', 'fixme'); // @TODO-2.0
    },
    bootstrap: function bootstrap() {// Populate the appropriate *Config field with defaults for your driver
    },
    cloudCredentials: Ember.computed('model.cloudCredentialId', 'driverName', function () {
      var driverName = this.driverName;
      return this.globalStore.all('cloudcredential').filter(function (cc) {
        switch (driverName) {
          case 'digitalocean':
            if (Ember.get(cc, 'isDo')) {
              return cc;
            }

            break;

          case 'amazonec2':
            if (Ember.get(cc, 'isAmazon')) {
              return cc;
            }

            break;

          case 'azure':
            if (Ember.get(cc, 'isAzure')) {
              return cc;
            }

            break;

          case 'linode':
            if (Ember.get(cc, 'isLinode')) {
              return cc;
            }

            break;

          case 'vmwarevsphere':
            if (Ember.get(cc, 'isVMware')) {
              return cc;
            }

            break;

          default:
            return;
        }
      }).sortBy('name');
    }),
    driverOptionsTitle: Ember.computed('driverName', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var driver = Ember.get(this, 'driverName');
      var key = "nodeDriver.displayName.".concat(driver);
      var name = (0, _util.ucFirst)(driver);

      if (intl.exists(key)) {
        name = intl.t(key);
      }

      return intl.t('nodeDriver.driverOptions', {
        driver: name
      });
    }),
    templateOptionsTitle: Ember.computed('settings.appName', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var appName = Ember.get(this, 'settings.appName');
      return intl.t('nodeDriver.templateOptions', {
        appName: appName
      });
    }),
    initEngineUrl: function initEngineUrl() {
      var engineInstallURL = null;
      var engineRegistryMirror = [];

      if (Ember.get(this, 'showEngineUrl')) {
        engineInstallURL = Ember.get(this, "settings.".concat(_constants.default.SETTING.ENGINE_URL)) || '';
      }

      Ember.setProperties(this, {
        'model.engineInstallURL': engineInstallURL,
        'model.engineRegistryMirror': engineRegistryMirror
      });
    },
    didInsertElement: function didInsertElement() {
      this._super();

      Ember.run.next(function () {
        try {
          var input = (0, _jquery.default)('INPUT')[0];

          if (input) {
            input.focus();
          }
        } catch (e) {}
      });
    },
    willSave: function willSave() {
      Ember.get(this, 'model').clearConfigsExcept("".concat(Ember.get(this, 'driverName'), "Config"));
      return this._super.apply(this, arguments);
    },
    validateCloudCredentials: function validateCloudCredentials() {
      var driversToValidate = ['amazonec2', 'azure', 'digitalocean', 'linode', 'vmwarevsphere'];
      var driverName = this.driverName;
      var cloudCredentialId = this.model.cloudCredentialId;
      var valid = false;

      if (driversToValidate.includes(driverName) && cloudCredentialId) {
        valid = true;
      } else {
        valid = false;
      }

      return valid;
    },
    doneSaving: function doneSaving() {
      // This triggers nodetemplates to recompute the display size/location
      Ember.get(this, 'model').notifyPropertyChange('displaySize');
      Ember.get(this, 'model').notifyPropertyChange('displayLocation');

      if (this.saved) {
        this.saved();
      }

      if (this.close) {
        this.close();
      }
    }
  });

  _exports.default = _default;
});